import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import xlsx from "xlsx";
import Moment from "moment";
import { format } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";

//import Firebase from "../../../providers/firebaseProvider";

import { database } from "../../providers/use-auth";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
  Spinner,
  ListGroup,
  Pagination,
} from "react-bootstrap";

import { getProfile } from "../../store/actions/AuthActions";

import axiosInstance from "../../services/AxiosInstance";

import moment from "moment";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const InstancePage = ({ props, history }) => {
  const dispatch = useDispatch();
  const [qr, setQr] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const profile = useSelector((state) => state.auth.profile);

  const [instances, setInstances] = useState([]);

  const [editInstanceModal, setEditInstanceModal] = useState(false);
  const [activeInstance, setActiveInstance] = useState(null);
  const [name, setName] = useState("");
  const [valid, setValid] = useState("365");
  const [expireDate, setExpireDate] = useState(new Date());
  const [errors, setErrors] = useState({});

  const sort = 500;
  let pagination = Array(Math.ceil(instances.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(getProfile());
    loadInstance();
  }, [dispatch]);

  useEffect(() => {
    if (instances) {
      setLoading(false);
      setTableData(instances.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [instances]);

  const loadInstance = async () => {
    const { data } = await axiosInstance.post("admin/instances");

    if (data.status) {
      setInstances(data.inatances);
    }
  };

  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(instances.slice(i * sort, (i + 1) * sort));
  };

  const onLogout = async (id) => {
    const { data } = await axiosInstance.post("admin/logout-instance", {
      instance_id: id,
    });

    if (data.status) {
      //setCampaigns(data.campaigns);
      console.log("logout success");
      swal("Logout", data.message, "success");
      loadInstance();
    } else {
      console.log("logout failed");
      swal("Logout", data.message, "error");
    }
  };

  const onDeleteInstance = async (id) => {
    const { data } = await axiosInstance.post("admin/delete-instance", {
      instance_id: id,
    });

    if (data.status) {
      //setCampaigns(data.campaigns);
      swal("Delete", data.message, "success");
      console.log("deleted success");
      loadInstance();
    } else {
      console.log("deleted failed");
      swal("Delete", data.message, "error");
    }
  };



  function onEditInstance(instance) {
    setActiveInstance(instance);

    setName(instance.name);
    setExpireDate(instance.expireDate);

    var startDate = Date.now();
    var endDate = moment(instance.expire).startOf('day');
    var result = endDate.diff(startDate, 'days');
    setValid(result);


    setEditInstanceModal(true);
  }

  const onSaveInstance = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = 'Name is Required';
    }

    if (valid === "") {
      error = true;
      errorObj.valid = 'Validity is Required';
    }

    if (expireDate === "") {
      error = true;
      errorObj.email = 'ExpireDate is Required';
    }

    setErrors(errorObj);
    if (!error) {

      try {

        const { data } = await axiosInstance.post(
          "admin/update-instance",
          { instance_id: activeInstance.id, name: name, valid: Number(valid), expire_date: expireDate }
        );

        if (data.status === false) {

          swal('Update Instance', data.message, "error");

        } else {

          setEditInstanceModal(false);
          swal('Update Instance', data.message, "success");



          setTimeout(() => {
            loadInstance();
          }, 500)

        }

      } catch (error) {

      }


    }
  }


  function dayLeft(instance) {

    if (instance.expire) {
      var startDate = Date.now();
      var endDate = moment(instance.expire).startOf('day');
      var result = endDate.diff(startDate, 'days');
      return result + " Days"
    } else {
      return "";
    }

  }


  return (



    <>

      <Modal className="modal fade" show={editInstanceModal} onHide={setEditInstanceModal} >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveInstance}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit Instance Details</h4>
                <button type="button" className="close" onClick={() => setEditInstanceModal(false)}><span>×</span></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input type="text" value={name} className="form-control" autocomplete="off"
                          name="name" required="required"
                          onChange={(e) =>
                            setName(e.target.value)
                          }
                          placeholder="Name"
                        />
                        {errors.name && <div className="text-danger fs-12">{errors.name}</div>}

                        <span className="validation-text"></span>
                      </div>
                    </div>


                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Valid</label>
                      <div className="contact-name">
                        <input type="number" value={valid} className="form-control" autocomplete="off"

                          onChange={(e) => {
                            setValid(e.target.value)

                            var currentDate = Date.now();
                            var ed = moment(currentDate);
                            ed.add(e.target.value, "days").format("YYYY-MM-DD hh:mm");

                            setExpireDate(ed);
                          }
                          }
                          placeholder="Validity In Days"
                        />
                        {errors.valid && <div className="text-danger fs-12">{errors.valid}</div>}

                        <span className="validation-text"></span>
                      </div>
                    </div>




                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Expire At</label>
                      <div className="contact-name">

                        <MuiPickersUtilsProvider className="form-control" utils={DateFnsUtils}>
                          <DateTimePicker
                            inputVariant="outlined"

                            label="Expire Date"
                            value={expireDate}
                            format="dd/MM/yyyy hh:mm"
                            placeholder="dd/MM/yyyy hh:mm"
                            onChange={(date) => {
                              setExpireDate(date)
                              var startDate = moment(expireDate).startOf('day');
                              var endDate = moment(date).startOf('day');

                              var result = endDate.diff(startDate, 'days');
                              setValid(result)

                            }}
                          />

                        </MuiPickersUtilsProvider>
                        {errors.expireDate && <div className="text-danger fs-12">{errors.expireDate}</div>}

                        <span className="validation-text"></span>
                      </div>
                    </div>





                  </div>
                </div>
              </div>
              <div className="modal-footer">

                <button type="button" onClick={() => setEditInstanceModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
                <button type="submit" className="btn btn-primary">Save</button>
              </div>
            </form>

          </div>
        </div>
      </Modal>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title className="mr-auto p-2">Whatsapp Instants</Card.Title>

              <div class="d-flex justify-content-end"></div>
            </Card.Header>
            <Card.Body>
              {instances.length == 0 ? (
                <p>No Instance Found</p>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No. </th>
                          <th>Instance Key </th>
                          <th>Name</th>
                          <th>User</th>
                          <th>Instance Status</th>
                          <th>Created At</th>
                          <th>Expire At</th>
                          <th>Day Left</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((instance, i) => (
                          <tr key={instance.code}>

                            <td>{i + 1}</td>

                            <td>
                              <strong className="text-success">
                                {instance.code}
                              </strong>
                            </td>

                            <td>{instance.name ?? ""}</td>

                            <td>
                              {instance.userId ? instance.userId.name : ""}
                            </td>

                            <td>
                              <div className="">
                                {instance.status ?? ""}
                                <br />
                                <small>{instance.message ?? ""}</small>
                              </div>
                            </td>

                            <td>
                              {format(
                                new Date(instance.createdAt),
                                "dd/MM/yyyy H:mma"
                              )}
                            </td>

                            <td>
                              {instance.expire ? format(
                                new Date(instance.expire),
                                "dd/MM/yyyy H:mma"
                              ) : ""}
                            </td>

                            <td>{dayLeft(instance)}</td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onLogout(instance.id)}
                                  >
                                    Logout
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) =>
                                      onDeleteInstance(instance.id)
                                    }
                                  >
                                    Delete
                                  </Dropdown.Item>


                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) =>
                                      onEditInstance(instance)
                                    }
                                  >
                                    Edit
                                  </Dropdown.Item>


                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Sr No. </th>
                          <th>Instance Key </th>
                          <th>Name</th>
                          <th>User</th>
                          <th>Instance Status</th>
                          <th>Created At</th>
                          <th>Expire At</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to&nbsp;
                        {instances.length < (activePage + 1) * sort
                          ? instances.length
                          : (activePage + 1) * sort}
                        &nbsp;of {instances.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={"page-" + i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>




                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InstancePage;
