import React, { useState } from 'react'
/// React router dom
import { Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from "./components/Dashboard/Home";
import MyProfile from "./components/Dashboard/MyProfile";


/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import ForgotPassword from './pages/forgot-password/forgot-password'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'

import Orders from './pages/Orders';
import OrderDetails from './pages/OrderDetails';
import Reseller from './pages/Reseller';
import ResellerDetails from './pages/ResellerDetails';
import Users from './pages/Users';
import CorporateMembers from './pages/CorporateUsers';
import Feedbacks from './pages/Feedbacks';
import Notifications from './pages/Notifications';
import Vouchers from './pages/Vouchers';
import Cards from './pages/Cards';
import Setting from './pages/Setting';
import Instance from './pages/Instances';

import VoucherCodes from './pages/VoucherCodes';
import Coupons from './pages/Coupons';

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import ManageSubscription from './pages/ManageSubscription'


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: "my-profile", component: MyProfile },
    { url: 'orders', component: Orders },
    { url: 'order/:id', component: OrderDetails },
    { url: 'resellers', component: Reseller },
    { url: 'reseller/:id', component: ResellerDetails },
    { url: 'users', component: Users },
    { url: 'manage-subscription', component: ManageSubscription },

    { url: 'feedbacks', component: Feedbacks },
    { url: 'notifications', component: Notifications },
    { url: 'cards', component: Cards },
    { url: 'vouchers', component: Vouchers },
    { url: 'voucher-codes/:id', component: VoucherCodes },
    { url: 'coupons', component: Coupons },
    { url: 'setting', component: Setting },

    { url: 'instances', component: Instance },

    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'forgot-password', component: ForgotPassword },
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },
  ]

  return (
    <>
      <div
        id={`${!pagePath ? 'main-wrapper' : ''}`}
        className={`${!pagePath ? 'show' : 'mh100vh'}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? 'rightside-event' : ''} ${!pagePath ? 'content-body' : ''
            }`}
        >
          <div
            className={`${!pagePath ? 'container-fluid' : ''}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  )
}

export default Markup
