import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  Fragment,
} from "react";
import {
  Table,
  Modal,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import UserSubscriptionTable from "./UserSubscriptionTable";

const ManageSubscription = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [addMemberModal, setAddMemberModal] = useState(false);
  const [editMemberModal, setEditMemberModal] = useState(false);

  const [countryListData, setCountryListData] = useState([])
  const [country, setCountry] = useState()
  const [name, setName] = useState("");
  const [validity, setValidity] = useState('')
  const [total, setTotal] = useState("");
  const [gst, setGst] = useState('')
  const [totalcost, setTotalcost] = useState('')
  const [validitydays, setValiditydays] = useState('')
  const [description, setDescription] = useState('')
  const [activeMember, setActiveMember] = useState("");
  let errorsObj = { countory: '', packageName: '', validity: '', cost: '', gst: '', totalcost: '', validitydays: '', description: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [activePage, setActivePage] = useState(0);
  const [totalPlan, setTotalPlan] = useState()
  const [itemsPerPage, setItemsPerPage] = useState(5)

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        loadUsers();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search, activePage, dispatch]);

  useEffect(() => {
    countryList()
  }, [])

  const countryList = async () => {
    const { data } = await axiosInstance.get("message/countries-list", {});

    console.log('data-=-=>', data)

    if (data.status) {
      setCountryListData(data?.countriesList);
    }
  };

  const handleCloseAddModal = () => {
    setAddMemberModal(false)
    setName('');
    setCountry('')
    setValidity('')
    setTotal('')
    setGst('')
    setTotalcost('')
    setValiditydays('')
    setDescription('')
  }

  const handleCloseEditModal = () => {
    setEditMemberModal(false)
    setName('');
    setCountry('')
    setValidity('')
    setTotal('')
    setGst('')
    setTotalcost('')
    setValiditydays('')
    setDescription('')
  }


  useEffect(() => {
    if (users) {
      setLoading(false);
    }
  }, [users]);

  const loadUsers = async () => {
    setLoading(true);
    const { data } = await axiosInstance.post("admin/subscription-list", {
      limit: itemsPerPage,
      page: activePage,
      search: search
    });
    console.log('data-------->', data)
    if (data?.status) {
      setUsers(data?.subscriptions);
      setTotalPlan(data?.totalSubscriptions);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onEditMember = (member) => {
    setActiveMember(member?.id);

    setName(member?.packageName);
    setCountry(member?.countory)
    setValidity(member?.validity)
    setTotal(member?.total)
    setGst(member?.gst)
    setTotalcost(member?.totalCost)
    setValiditydays(member?.validityindays)
    setDescription(member?.description)

    setEditMemberModal(true);
  }

  const onSaveMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (country === "") {
      error = true;
      errorObj.nacountryme = "Country field is Required";
    }
    if (name === "") {
      error = true;
      errorObj.name = "Package Name is Required";
    }
    if (validity === "") {
      error = true;
      errorObj.phone = "Validity is Required";
    }
    if (total === "") {
      error = true;
      errorObj.phone = "Total is Required";
    }
    if (gst === "") {
      error = true;
      errorObj.phone = "GST Number is Required";
    }
    if (totalcost === "") {
      error = true;
      errorObj.phone = "TotalCost is Required";
    }
    if (validitydays === "") {
      error = true;
      errorObj.phone = "ValidityDays is Required";
    }
    if (description === "") {
      error = true;
      errorObj.phone = "Description is Required";
    }
    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.put(`admin/update-subscription/${activeMember}`, {
          countory: country,
          packageName: name,
          validity: validity,
          total: total,
          gst: gst,
          totalCost: totalcost,
          validityindays: validitydays,
          description: description,
        });

        if (data.status === false) {
          swal("Update User", data.message, "error");
        } else {
          setEditMemberModal(false);
          swal("Update User", data.message, "success");
          setName('');
          setCountry('')
          setValidity('')
          setTotal('')
          setGst('')
          setTotalcost('')
          setValiditydays('')
          setDescription('')


          setTimeout(() => {
            loadUsers();
          }, 500);
        }
      } catch (error) { }
    }
  };

  const onAddMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (country === "") {
      error = true;
      errorObj.nacountryme = "Country field is Required";
    }
    if (name === "") {
      error = true;
      errorObj.name = "Package Name is Required";
    }
    if (validity === "") {
      error = true;
      errorObj.phone = "Validity is Required";
    }
    if (total === "") {
      error = true;
      errorObj.phone = "Total is Required";
    }
    if (gst === "") {
      error = true;
      errorObj.phone = "GST Number is Required";
    }
    if (totalcost === "") {
      error = true;
      errorObj.phone = "TotalCost is Required";
    }
    if (validitydays === "") {
      error = true;
      errorObj.phone = "ValidityDays is Required";
    }
    if (description === "") {
      error = true;
      errorObj.phone = "Description is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/create-subscription", {
          countory: country,
          packageName: name,
          validity: validity,
          total: total,
          gst: gst,
          totalCost: totalcost,
          validityindays: validitydays,
          description: description,
        });
        console.log('data-=========>', data)
        if (data.status === false) {
          swal("Add User", data.message, "error");
        } else {
          setAddMemberModal(false);
          swal("Add User", data.message, "success");

          setName('');
          setCountry('')
          setValidity('')
          setTotal('')
          setGst('')
          setTotalcost('')
          setValiditydays('')
          setDescription('')

          setTimeout(() => {
            loadUsers();
          }, 500);
        }
      } catch (error) { }
    }
  };

  return (
    <Fragment>
      <Modal
        className="modal fade"
        show={addMemberModal}
        onHide={handleCloseAddModal}
        backdrop="true" keyboard={true}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAddMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Plan</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => handleCloseAddModal()}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group">
                      <label>Country</label>
                      <select
                        className="form-control"
                        id="inputState"
                        value={country}
                        onChange={(e) =>
                          setCountry(e.target.value)
                        }
                      >
                        {countryListData?.map((row) => {
                          return (
                            <option value={row?.name}>{row?.name}</option>
                          )
                        })}
                      </select>

                      {errors.addState && <div className="text-danger fs-12">{errors.addState}</div>}
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Package Name</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            value={name}
                            className="form-control"
                            autocomplete="off"
                            name="name"
                            required="required"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Package"
                          />
                          {errors.name && (
                            <div className="text-danger fs-12">{errors.name}</div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Validity</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            value={validity}
                            className="form-control"
                            autocomplete="off"
                            name="name"
                            required="required"
                            onChange={(e) => setValidity(e.target.value)}
                            placeholder="Enter Valisity"
                          />
                          {errors.validity && (
                            <div className="text-danger fs-12">
                              {errors.validity}
                            </div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Total</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            value={total}
                            className="form-control"
                            autocomplete="off"
                            name="total"
                            required="required"
                            onChange={(e) => setTotal(e.target.value)}
                            placeholder="Enter Cost"
                          />
                          {errors.total && (
                            <div className="text-danger fs-12">{errors.total}</div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">GST %</label>
                        <div className="contact-name">
                          <input
                            type="number"
                            value={gst}
                            className="form-control"
                            autocomplete="off"
                            name="gst"
                            required="required"
                            onChange={(e) => setGst(e.target.value)}
                            placeholder="Enter GST"
                          />
                          {errors.gst && (
                            <div className="text-danger fs-12">
                              {errors.gst}
                            </div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Total Cost</label>
                        <div className="contact-name">
                          <input
                            type="number"
                            value={totalcost}
                            className="form-control"
                            autocomplete="off"
                            name="totalcost"
                            required="required"
                            onChange={(e) => setTotalcost(e.target.value)}
                            placeholder="Enter Total Cost"
                          />
                          {errors.totalcost && (
                            <div className="text-danger fs-12">{errors.totalcost}</div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Validity in Days</label>
                        <div className="contact-name">
                          <input
                            type="number"
                            value={validitydays}
                            className="form-control"
                            autocomplete="off"
                            name="validitydays"
                            required="required"
                            onChange={(e) => setValiditydays(e.target.value)}
                            placeholder="Enter Days"
                          />
                          {errors.validitydays && (
                            <div className="text-danger fs-12">
                              {errors.validitydays}
                            </div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        Description
                      </label>
                      <div className="contact-name">
                        <textarea
                          value={description}
                          className="form-control"
                          autocomplete="off"
                          name="description"
                          onkeypress="return event.charCode>=48 && event.charCode<=57"
                          required="required"
                          min={1}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Enter Description"
                        />

                        {errors.description && (
                          <div className="text-danger fs-12">
                            {errors.description}
                          </div>
                        )}
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAddMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={editMemberModal}
        onHide={handleCloseEditModal}
        backdrop="true" keyboard={true}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit User</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => handleCloseEditModal()}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group">
                      <label>Country</label>
                      <select
                        className="form-control"
                        id="inputState"
                        value={country}
                        onChange={(e) =>
                          setCountry(e.target.value)
                        }
                      >
                        {countryListData?.map((row) => {
                          return (
                            <option value={row?.name}>{row?.name}</option>
                          )
                        })}
                      </select>

                      {errors.addState && <div className="text-danger fs-12">{errors.addState}</div>}
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Package Name</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            value={name}
                            className="form-control"
                            autocomplete="off"
                            name="packageName"
                            required="required"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Package"
                          />
                          {errors.name && (
                            <div className="text-danger fs-12">{errors.name}</div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Validity</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            value={validity}
                            className="form-control"
                            autocomplete="off"
                            name="name"
                            required="required"
                            onChange={(e) => setValidity(e.target.value)}
                            placeholder="Enter Valisity"
                          />
                          {errors.validity && (
                            <div className="text-danger fs-12">
                              {errors.validity}
                            </div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Total</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            value={total}
                            className="form-control"
                            autocomplete="off"
                            name="total"
                            required="required"
                            onChange={(e) => setTotal(e.target.value)}
                            placeholder="Enter Cost"
                          />
                          {errors.total && (
                            <div className="text-danger fs-12">{errors.total}</div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">GST %</label>
                        <div className="contact-name">
                          <input
                            type="number"
                            value={gst}
                            className="form-control"
                            autocomplete="off"
                            name="gst"
                            required="required"
                            onChange={(e) => setGst(e.target.value)}
                            placeholder="Enter GST"
                          />
                          {errors.gst && (
                            <div className="text-danger fs-12">
                              {errors.gst}
                            </div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Total Cost</label>
                        <div className="contact-name">
                          <input
                            type="number"
                            value={totalcost}
                            className="form-control"
                            autocomplete="off"
                            name="totalcost"
                            required="required"
                            onChange={(e) => setTotalcost(e.target.value)}
                            placeholder="Enter Total Cost"
                          />
                          {errors.totalcost && (
                            <div className="text-danger fs-12">{errors.totalcost}</div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="text-black font-w500">Validity in Days</label>
                        <div className="contact-name">
                          <input
                            type="number"
                            value={validitydays}
                            className="form-control"
                            autocomplete="off"
                            name="validitydays"
                            required="required"
                            onChange={(e) => setValiditydays(e.target.value)}
                            placeholder="Enter Days"
                          />
                          {errors.validitydays && (
                            <div className="text-danger fs-12">
                              {errors.validitydays}
                            </div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        Description
                      </label>
                      <div className="contact-name">
                        <textarea
                          value={description}
                          className="form-control"
                          autocomplete="off"
                          name="description"
                          onkeypress="return event.charCode>=48 && event.charCode<=57"
                          required="required"
                          min={1}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Enter Description"
                        />

                        {errors.description && (
                          <div className="text-danger fs-12">
                            {errors.description}
                          </div>
                        )}
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div class="mr-auto">
              <h4 className="card-title">Manage Subscription</h4>
            </div>

            <div class="">
              <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadUsers();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <Button
                className="btn btn-primary font-w600 mr-2"
                onClick={() => setAddMemberModal(true)}
              >
                Add Plan
              </Button>
            </div>
          </div>

          <div className="card-body">
            {
              loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : users?.length == 0 ? (
                <p>No User Found</p>
              ) : (
                <UserSubscriptionTable
                  users={users}
                  onEditMember={onEditMember}
                  loadPlans={loadUsers}
                  setActivePage={setActivePage}
                  activePage={activePage}
                  totalPlan={totalPlan}
                  itemsPerPage={itemsPerPage}
                />
              )
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ManageSubscription;
