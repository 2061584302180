import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

import moment from "moment";


import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";


import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//const Orders = () => {
const Reseller = ({ props, history }) => {
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const sort = 50;
  let pagination = Array(Math.ceil(agents.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [activeUser, setActiveUser] = useState(null);

  const [createInstanceModal, setCreateInstanceModal] = useState(false);

  const [total, setTotal] = useState("1");
  const [valid, setValid] = useState("365");
  const [expireDate, setExpireDate] = useState(new Date());
  const [errors, setErrors] = useState({});




  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  let errorsObj = { name: '', email: "", phone: "" };

  const [editMemberModal, setEditMemberModal] = useState(false);
  const [activeMember, setActiveMember] = useState("");



  const onCreateInstance = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (total === "") {
      error = true;
      errorObj.total = "Instance is Required";
    }

    if (valid === "") {
      error = true;
      errorObj.valid = "Validity is Required";
    }

    if (expireDate === "") {
      error = true;
      errorObj.email = "ExpireDate is Required";
    }

    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "admin/assign-instance-to-reseller",
          {
            reseller_id: activeUser._id,
            instance: total,
            expire_date: expireDate,
          }
        );

        if (data.status === false) {
          //toast.error(data.message);
          swal("Assign Instance", data.message, "error");
        } else {
          //toast.success(data.message);
          swal("Assign Instance", data.message, "success");
          setTotal("1");
          setValid("365");
          setExpireDate(Date.now());

          setTimeout(() => {
            setCreateInstanceModal(false);
          }, 500);

          setTimeout(() => {
            setCreateInstanceModal(false);
            loadAgents();
          }, 500);
        }
      } catch (error) { }
    }
  };

  // On Edit Profile
  const onSendCounter = (member) => {
    setTotal("1");
    setValid("365");

    var currentDate = Date.now();
    var ed = moment(currentDate);
    ed.add(365, "days").format("YYYY-MM-DD hh:mm");
    setExpireDate(ed);

    setActiveUser(member);
    setCreateInstanceModal(true);
  };

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadAgents();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    loadAgents();
  }, [dispatch]);

  useEffect(() => {
    if (agents) {
      setLoading(false);
      setTableData(agents.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [agents]);

  const loadAgents = async () => {
    setLoading(true);
    const { data } = await axiosInstance.post("admin/resellers", {
      search: search,
    });

    if (data.status) {
      setAgents(data.resellers);
    }
    setLoading(false);
  };

  const initXlsData = async () => {
    var dummy = [];
    agents.map((data, i) => {
      var address = "";
      if (data.address) {
        if (data.address.addressLine1) {
          address =
            (data.address.addressLine1 ?? "") +
            ", " +
            (data.address.addressLine2 ?? "") +
            ", " +
            (data.address.city ?? "") +
            ", " +
            (data.address.state ?? "") +
            ", " +
            (data.address.country ?? "") +
            " - " +
            (data.address.zip ?? "");
        } else {
          address = "Not Available";
        }
      }

      dummy.push({
        sr_no: i + 1,
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        address: address,
        instance: data.instance,
        joined: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
        status: data.status === "active" ? "Activated" : "Deactivated",
      });
    });

    setXlsData(dummy);
  };

  /*  const changeAgentStatus = async (agentId,status) => {

      // const { data } = await axiosInstance.post(
      //    "admin/user-status",
      //    {
      //       user_id:agentId,
      //       status:status,
      //       sub_agent:false
      //    }
      // );

      const { data } = await axiosInstance.post("admin/user-status", {
         user_id: agentId,
         status: status,
       });

      if(data.status){
         swal('Reseller', data.message, "success");

         var dummy = [...agents];
         
         const agentIndex = dummy.findIndex(
            (x) => x.id === agentId
         );

         dummy[agentIndex].status = status;
         console.log("order index - "+agentIndex);

         setAgents(dummy);
         onClick(activePage);

      }else{
         swal('Reseller', data.message, "error");
      }

   } */

  const changeUserStatus = async (userId, status) => {
    const { data } = await axiosInstance.post("admin/user-status", {
      user_id: userId,
      status: status,
    });

    if (data.status) {
      swal("User", data.message, "success");

      var dummy = [...agents];

      const index = dummy.findIndex((x) => x._id === userId);

      if (index != -1) {
        dummy[index].status = status;
        console.log("order index - " + index);
      }

      setAgents(dummy);
      onClick(activePage);
    } else {
      swal("User", data.message, "error");
    }
  };

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(agents.slice(i * sort, (i + 1) * sort));
  };

  function onEditMember(member) {
    setActiveMember(member._id);

    setPhone(member.phone);
    setEmail(member.email);
    setName(member.name);

    setEditMemberModal(true);
  }

  const onSaveMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = 'Name is Required';
    }

    if (phone === "") {
      error = true;
      errorObj.phone = 'Phone Number is Required';
    }

    if (email === "") {
      error = true;
      errorObj.email = 'Email is Required';
    } else if (!validateEmail(email)) {
      error = true;
      errorObj.email = 'Enter Valid Email!';
    }

    setErrors(errorObj);
    if (!error) {

      try {

        const { data } = await axiosInstance.post(
          "admin/update-user",
          { name: name, phone: phone, email: email, user_id: activeMember }
        );

        if (data.status === false) {

          swal('Update User', data.message, "error");

        } else {

          setEditMemberModal(false);
          swal('Update User', data.message, "success");



          setTimeout(() => {
            loadAgents();

            // var dummy = [...users];

            // const index = dummy.findIndex((x) => x.id === activeMember);

            // dummy[index].name = name;
            // dummy[index].phone = "+91"+phone;
            // dummy[index].email = email;
            // console.log("order index - " + index);

            // setUsers(users);
            // //onClick(activePage);

            // setPhone("");
            // setEmail("");
            // setName("");

          }, 500)

        }

      } catch (error) {

      }


    }
  }



  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={createInstanceModal}
        onHide={setCreateInstanceModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onCreateInstance}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  Assign Instance To Reseller
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setCreateInstanceModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Assign Instance in {activeUser ? activeUser.name : ""}(
                      {activeUser ? activeUser.phone : ""}) Account
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Number Of Instance
                      </label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={total}
                          className="form-control"
                          autocomplete="off"
                          name="counter"
                          onkeypress="return event.charCode>=48 && event.charCode<=57"
                          required="required"
                          min={1}
                          onChange={(e) => setTotal(e.target.value)}
                          placeholder="Total Instance"
                        />

                        {errors.total && (
                          <div className="text-danger fs-12">
                            {errors.total}
                          </div>
                        )}
                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Valid</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={valid}
                          className="form-control"
                          autocomplete="off"
                          onChange={(e) => {
                            setValid(e.target.value);

                            var currentDate = Date.now();
                            var ed = moment(currentDate);
                            ed.add(e.target.value, "days").format(
                              "YYYY-MM-DD hh:mm"
                            );

                            setExpireDate(ed);
                          }}
                          placeholder="Validity In Days"
                        />
                        {errors.valid && (
                          <div className="text-danger fs-12">
                            {errors.valid}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Expire At</label>
                      <div className="contact-name">
                        <MuiPickersUtilsProvider
                          className="form-control"
                          utils={DateFnsUtils}
                        >
                          <DateTimePicker
                            inputVariant="outlined"
                            label="Expire Date"
                            value={expireDate}
                            format="dd/MM/yyyy hh:mm"
                            placeholder="dd/MM/yyyy hh:mm"
                            onChange={(date) => {
                              setExpireDate(date);
                              var startDate = moment(expireDate).startOf("day");
                              var endDate = moment(date).startOf("day");

                              var result = endDate.diff(startDate, "days");
                              setValid(result);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {errors.expireDate && (
                          <div className="text-danger fs-12">
                            {errors.expireDate}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setCreateInstanceModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Assign
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>


      <Modal className="modal fade" show={editMemberModal} onHide={setEditMemberModal} >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit User</h4>
                <button type="button" className="close" onClick={() => setEditMemberModal(false)}><span>×</span></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input type="text" value={name} className="form-control" autocomplete="off"
                          name="name" required="required"
                          onChange={(e) =>
                            setName(e.target.value)
                          }
                          placeholder="Name"
                        />
                        {errors.name && <div className="text-danger fs-12">{errors.name}</div>}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Phone Number</label>
                      <div className="contact-name">
                        {/* <input type="text" value={phone}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setPhone(e.target.value)
                                          }
														placeholder="Phone Number with country code"
													/> */}

                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phone}
                          defaultCountry="IN"
                          onChange={setPhone}
                        />

                        {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}

                        <span className="validation-text"></span>
                      </div>
                    </div>


                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input type="email" value={email} className="form-control" autocomplete="off"
                          name="name" required="required"
                          onChange={(e) =>
                            setEmail(e.target.value)
                          }
                          placeholder="Email"
                        />
                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}

                        <span className="validation-text"></span>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
              <div className="modal-footer">

                <button type="button" onClick={() => setEditMemberModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
                <button type="submit" className="btn btn-primary">Save</button>
              </div>
            </form>

          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Resellers</h4>

              <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadAgents();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <CSVLink
                data={xlsData}
                filename={"resellers.csv"}
                className="btn btn-primary"
                target="_blank"
                asyncOnClick={true}
                onClick={async (event, done) => {
                  console.log("You click the link");
                  await initXlsData();
                  done(); // 👍🏻 You are stopping the handling of component
                }}
              >
                Export
              </CSVLink>
            </div>
            <div className="card-body">
              {loading || agents.length == 0 ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No.</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Instance</th>
                          <th>Joinded</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((d, i) => (
                          <tr key={d.id}>
                            <td>{i + 1}</td>
                            <td>
                              <Link to={"/reseller/" + d._id}>
                                {d.name}
                                <br />
                                <small>{d.email}</small>
                              </Link>
                            </td>
                            <td>{d.phone}</td>
                            <td>
                              <strong>{d.instances.length}</strong>
                            </td>
                            <td>
                              {format(
                                new Date(d.createdAt),
                                "dd/MM/yyyy H:mma"
                              )}
                            </td>
                            <td>
                              {d.status === "active" ? (
                                <Badge variant="success light">
                                  Activated
                                  <span className="ml-1 fa fa-check"></span>
                                </Badge>
                              ) : d.status === "deactive" ? (
                                <Badge variant="danger light">
                                  Deactivated
                                  <span className="ml-1 fa fa-ban"></span>
                                </Badge>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>


                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onEditMember(d)}
                                  >
                                    Edit Profile
                                  </Dropdown.Item>


                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) =>
                                      changeUserStatus(
                                        d._id,
                                        d.status === "active"
                                          ? "deactive"
                                          : "active"
                                      )
                                    }
                                  >
                                    {d.status === "active"
                                      ? "Deactive Now"
                                      : "Active Now"}
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onSendCounter(d)}
                                  >
                                    Assign Instant
                                  </Dropdown.Item>

                                  {/*  <div className="dropdown-divider"></div>
                                     <Dropdown.Item
                                        href="#"
                                        className="text-danger"
                                     >
                                        Delete
                                     </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Reseller Id</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Counters</th>
                          <th>Joinded</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to&nbsp;
                        {agents.length < (activePage + 1) * sort
                          ? agents.length
                          : (activePage + 1) * sort}
                        &nbsp;of {agents.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Reseller;
