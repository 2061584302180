import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Table,
  Pagination,
  Dropdown,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

const UserSubscriptionTable = ({ users, onEditMember, loadPlans, activePage, setActivePage, totalPlan, itemsPerPage }) => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState(users);

  const totalPages = Math.ceil(totalPlan / itemsPerPage);
  let pagination = Array(totalPages).fill().map((_, i) => i + 1);

  useEffect(() => {
    const startIndex = activePage * itemsPerPage;
    const endIndex = (activePage + 1) * itemsPerPage;
  }, [activePage, usersList]);

  const onClick = (i) => {
    setActivePage(i);
  };

  const onDeletePlan = async (id) => {
    const { data } = await axiosInstance.delete(`admin/delete-subscription/${id}`, {});

    if (data.status) {
      swal("Delete", data.message, "success");
      console.log("deleted success");
      loadPlans();
    } else {
      console.log("deleted failed");
      swal("Delete", data.message, "error");
    }
  };

  return (
    <>
      <Table responsive className="w-100">
        <div id="example_wrapper" className="dataTables_wrapper">
          <table id="example" className="display w-100 dataTable">
            <thead>
              <tr role="row">
                <th key="0">Sr No.</th>
                <th key="1">PackageName</th>
                <th key="2">validity</th>
                <th key="3">gst</th>
                <th key="4">totalCost</th>
                <th key="5">validityInDays</th>
                <th key="5">description</th>
                <th key="6">Action</th>
              </tr>
            </thead>
            <tbody style={{ height: "300px" }}>
              {usersList?.map((d, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d?.packageName}</td>
                  <td>{d?.validity}</td>
                  <td>{d?.gst} %</td>
                  <td>{d?.totalCost}</td>
                  <td>{d?.validityindays}</td>
                  <td>{d?.description}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="table-dropdown icon-false"
                      >
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                          </g>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={(e) => onEditMember(d)}>
                          Edit Plan
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={(e) => onDeletePlan(d.id)}>
                          Delete Plan
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {activePage * itemsPerPage + 1} to &nbsp;
              {Math.min((activePage + 1) * itemsPerPage, usersList.length)} &nbsp;
              of {usersList.length} entries
            </div>
            <div className="dataTables_paginate paging_simple_numbers">
              <Pagination className="pagination-primary pagination-circle" size="lg">
                <li className="page-item page-indicator" onClick={() => activePage > 0 && onClick(activePage - 1)}>
                  <Link className="page-link" to="#">
                    <i className="la la-angle-left" />
                  </Link>
                </li>
                {pagination.map((number, i) => (
                  <Pagination.Item
                    key={"page-" + i}
                    active={activePage === i}
                    onClick={() => onClick(i)}
                  >
                    {number}
                  </Pagination.Item>
                ))}
                <li className="page-item page-indicator" onClick={() => activePage + 1 < totalPages && onClick(activePage + 1)}>
                  <Link className="page-link" to="#">
                    <i className="la la-angle-right" />
                  </Link>
                </li>
              </Pagination>
            </div>
          </div>
        </div>
      </Table>
    </>
  );
};

export default UserSubscriptionTable;
