import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import axiosInstance from '../../services/AxiosInstance';
import swal from "sweetalert";
import { format } from "date-fns";

import QRCode from "react-qr-code";

import { Table, Pagination, Badge,Dropdown,Spinner } from "react-bootstrap";

import ColorPicker from "material-ui-color-picker";

const OrderDetails = ({ match, history }) => {

   const dispatch = useDispatch();
   const [order, setOrder] = useState(null);
   const [cards, setCards] = useState([]);
   const [color, setColor] = useState("");


   

   useEffect(() => {
   
   loadOrderDetails(match.params.id);
  
   }, [match]);

   useEffect(() => {
   
      if(order){
         loadCardsDetails(order.id);
      }
      
   }, [order]);


   const qrCode = (cardId,size,color,bgcolor) => {
      return ("http://api.qrserver.com/v1/create-qr-code/?data="+encodeURI("https://profile.tap1ce.com/"+cardId)+"&ecc=H"+"&size="+size+"x"+size+"&color="+color+"&bgcolor="+bgcolor+"&format=png");
   }

    const loadOrderDetails = async (orderId) => {
      const { data } = await axiosInstance.get(
         "admin/orders/"+orderId
       );
 
       if(data.status){
         setOrder(data.order);
       }
    }

    const loadCardsDetails = async (orderId) => {
      const { data } = await axiosInstance.post(
         "admin/cards",
         {order_id:orderId}
       );
 
       if(data.status){
         setCards(data.cards);
       }
    }

    const cardQRLink = (orderItemId,size,color,bgcolor) => {
      const cardIndex = cards.findIndex(
			(x) => x.orderItemId === orderItemId
		  );
		  if (cardIndex != -1) {
			//const link = "https://profile.tap1ce.com/"+cards[cardIndex]._id;
         //return link;
         return qrCode(cards[cardIndex]._id,size,color,bgcolor);
		  }else{
			return "";
		  }
    }

    const cardQRLink2 = (orderItemId) => {
      const cardIndex = cards.findIndex(
			(x) => x.orderItemId === orderItemId
		  );
		  if (cardIndex != -1) {
			const link = "https://profile.tap1ce.com/"+cards[cardIndex]._id;
         return link;
         //return qrCode(cards[cardIndex]._id,size,color,bgcolor);
		  }else{
			return "";
		  }
    }


    const productCoverImage = (item,isFront) => {

      var productFrontImage = "";
      var productBackImage = "";

      const variationIndex = item.productId.variations.findIndex(
         (x) => x._id === item.variationId
      );

      if(variationIndex >= 0){
         productFrontImage = item.productId.variations[variationIndex].dimensions.template.frontImage;
         productBackImage = item.productId.variations[variationIndex].dimensions.template.backImage;
      }

      return isFront?productFrontImage:productBackImage;
    }

    const downloadInvoice = async () => {
      const { data } = await axiosInstance.post(
         "admin/order-invoice",
         {
            order_id:match.params.id
         }
       );
 
       if(data.status){
         
         const link = document.createElement("a");
         link.href = data.data;
         document.body.appendChild(link);
         link.click();
         // Clean up and remove the link
         link.parentNode.removeChild(link);

       }
    }



    const onQRCownload = (id) => {
      const svg = document.getElementById(id);
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = id;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

      /* const cardIndex = cards.findIndex(
			(x) => x.orderItemId === id
		  );
		  if (cardIndex != -1) {
			//const link = "https://profile.tap1ce.com/"+cards[cardIndex]._id;
         //return link;
         var link =  qrCode(cards[cardIndex]._id,size,color);

         const downloadLink = document.createElement("a");
         downloadLink.download = id;
         downloadLink.href = `${link}`;
         downloadLink.click();

		  }else{
			//return "";
		  } */

    };


   return (
      <Fragment>
         
         <div className="row">
            <div className="col-lg-12">
            {order == null ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:
            
            (<div className="card mt-3">
                  <div className="card-header">
                     {" "}
                     #{order.id}  {order.paymentStatus === "paid" ? (
                                          <Badge variant="success light">
                                             Paid
                                          </Badge>
                                       ) : (
                                          <Badge variant="danger light">
                                             Unpaid
                                          </Badge>
                                       )
                                    } <strong>{format(new Date(order.createdAt), "dd/MM/yyyy H:mma")}</strong>{" "}
                     <span className="float-right">
                        <strong>Status:</strong> {order.status}
                     </span>{" "}
                  </div>
                  <div className="card-body">
                    
					 <div className="table-responsive">
                        <table className="table table-striped">
                           <thead>
                              <tr>
                                 <th className="center">#</th>
                                 <th>Plan</th>
                                 <th>pack</th>
                                 <th className="right">Amount</th>
                                 <th className="center">Qty</th>
                                 <th className="right">Total</th>
                              </tr>
                           </thead>
                           <tbody>

                              
                              
                                    <tr>
                                    <td className="center">1</td>

                                   
                                    <td className="left strong">
                                       
                                       {order.packId.type.toUpperCase()}</td>

                                       <td className="left strong">
                                       
                                       {order.packId.name.toUpperCase()}</td>
                                   
                                    <td className="right">{order.currency} {order.packId.salePrice}</td>
                                    <td className="center">1</td>
                                    <td className="right">{order.currency} {order.packId.salePrice}</td>
                                 </tr>
                              

                             
                           </tbody>
                        </table>
                     </div>
                     <div className="row">
                        <div className="col-lg-4 col-sm-5"> </div>
                        <div className="col-lg-4 col-sm-5 ml-auto">
                           <table className="table table-clear">
                              <tbody>
                                 <tr>
                                    <td className="left">
                                       <strong>Subtotal</strong>
                                    </td>
                                    <td className="right">{order.currency} {order.packId.salePrice}</td>
                                 </tr>
                                 <tr>
                                    <td className="left">
                                       <strong>Discount (  {order.discountTotal != 0 ?((order.discountTotal * 100)/order.discountTotal):0}%)</strong>
                                    </td>
                                    <td className="right">{order.currency} {order.discountTotal}</td>
                                 </tr>
                                 {/* <tr>
                                    <td className="left">
                                       <strong>VAT (10%)</strong>
                                    </td>
                                    <td className="right">$679,76</td>
                                 </tr> */}
                                 <tr>
                                    <td className="left">
                                       <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                       <strong>{order.currency} {order.orderTotal}</strong>
                                       <br />
                                       {/* <strong>0.15050000 BTC</strong> */}
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
           )}
         </div>
         </div>
      </Fragment>
   );
};

export default OrderDetails;
