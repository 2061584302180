import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Dropdown, Button, Modal } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { SRLWrapper } from "simple-react-lightbox";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from '../../../services/AxiosInstance';

import swal from "sweetalert";

const MyProfile = () => {
   const [activeToggle, setActiveToggle] = useState("posts");
   const [sendMessage, setSendMessage] = useState(false);
   const [replay, setReplay] = useState(false);

   const dispatch = useDispatch();

   let errorsObj = { name: '', type: '', gst: "", managerName: "", managerNumber: "", addLine1: "", addLine2: "", addCity: "", addState: "", addLandmark: "", addZipcode: "" };
   const [errors, setErrors] = useState(errorsObj);

   const [name, setName] = useState("");
   const [type, setType] = useState("");
   const [gst, setGST] = useState("");
   const [managerName, setManagerName] = useState("");
   const [managerNumber, setManagerNumber] = useState("");
   const [addLine1, setAddLine1] = useState("");
   const [addLine2, setAddLine2] = useState("");
   const [addCity, setAddCity] = useState("");
   const [addState, setAddState] = useState("State");
   const [addLandmark, setAddLandmark] = useState("");
   const [addZipcode, setAddZipcode] = useState("");

   //const [profile, setProfile] = useState({});

   const options = {
      settings: {
         overlayColor: "#000000",
      },
   };

   useEffect(() => {
      loadDetails();
   }, [dispatch]);

   const loadDetails = async () => {
      const { data } = await axiosInstance.get(
         "auth/profile"
      );

      if (data.user) {
         //setProfile(data.user);
         const profile = data.user;
         setName(profile.name ?? "");
         setType(profile.industryType ?? "");
         setGST(profile.gstNumber ?? "");
         setManagerName(profile.managerName ?? "");
         setManagerNumber(profile.managerPhoneNumber ?? "");
         if (profile.address) {
            setAddLine1(profile.address.addressLine1 ?? "");
            setAddLine2(profile.address.addressLine2 ?? "");
            setAddCity(profile.address.city ?? "");
            setAddState(profile.address.state ?? "");
            setAddZipcode(profile.address.zip ?? "");
         }
      }

      console.log("details data - " + JSON.stringify(data));
   }

   const onSaveProfile = async (e) => {
      e.preventDefault();
      let error = false;
      const errorObj = { ...errorsObj };


      if (name === '') {
         errorObj.name = 'Company Name is Required';
         error = true;
      }
      if (type === '') {
         errorObj.type = 'Industry Type is Required';
         error = true;
      }
      if (managerName === '') {
         errorObj.managerName = 'Manager Name is Required';
         error = true;
      }
      if (managerNumber === '') {
         errorObj.managerNumber = 'Manager Phone Number is Required';
         error = true;
      }
      if (gst === '') {
         errorObj.gst = 'GST Number is Required';
         error = true;
      }
      if (addLine1 === '') {
         errorObj.addLine1 = 'Address Line 1 is Required';
         error = true;
      }
      if (addLine2 === '') {
         errorObj.addLine2 = 'Address Line 2 is Required';
         error = true;
      }
      if (addCity === '') {
         errorObj.addCity = 'Address City is Required';
         error = true;
      }
      if (addState === '') {
         errorObj.addState = 'Address State is Required';
         error = true;
      }
      if (addZipcode === '') {
         errorObj.addZipcode = 'Address Pincode is Required';
         error = true;
      }
      setErrors(errorObj);
      if (error) {
         console.log("error on save");
         return;
      }
      console.log("no error on save");
      //save data

      const formData = {
         name: name,
         industry_type: type,
         gst_number: gst,
         manager_name: managerName,
         manager_phone_number: managerNumber,
         add_line_1: addLine1,
         add_line_2: addLine2,
         add_city: addCity,
         add_state: addState,
         add_zipcode: addZipcode
      };

      console.log("form data - " + formData)

      const { data } = await axiosInstance.post(
         "corporate/update-details",
         formData
      );

      if (data.status) {
         swal('Update Profile', data.message, "success");
      } else {
         swal('Update Profile', data.message, "error");
      }

   }

   return (
      <Fragment>
         <PageTitle activeMenu="My Profile" motherMenu="Dashboard" />


         <div className="row">

            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">


                     <div
                        id=""

                     >
                        <div className="pt-3">
                           <div className="settings-form">
                              <h4 className="text-primary">
                                 Inforamation
                              </h4>
                              <form
                                 onSubmit={onSaveProfile}
                              >
                                 <div className="form-row">
                                    <div className="form-group col-md-6">
                                       <label>Name</label>
                                       <input
                                          type="text"
                                          value={name}
                                          onChange={(e) =>
                                             setName(e.target.value)
                                          }
                                          placeholder="Company Name"
                                          className="form-control"

                                       />
                                       {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                    </div>
                                    <div className="form-group col-md-6">
                                       <label>Industry Type</label>
                                       <input
                                          type="text"
                                          value={type}
                                          onChange={(e) =>
                                             setType(e.target.value)
                                          }
                                          placeholder="Industry Type"
                                          className="form-control"
                                       />
                                       {errors.type && <div className="text-danger fs-12">{errors.type}</div>}
                                    </div>
                                 </div>

                                 <div className="form-group">
                                    <label>GST No</label>
                                    <input
                                       type="text"
                                       value={gst}
                                       onChange={(e) =>
                                          setGST(e.target.value)
                                       }
                                       placeholder="GST No."
                                       className="form-control"
                                    />
                                    {errors.gst && <div className="text-danger fs-12">{errors.gst}</div>}
                                 </div>

                                 <div className="form-row">
                                    <div className="form-group col-md-6">
                                       <label>Manager Name</label>
                                       <input
                                          type="text"
                                          value={managerName}
                                          onChange={(e) =>
                                             setManagerName(e.target.value)
                                          }
                                          placeholder="Manager Name"
                                          className="form-control"

                                       />
                                       {errors.managerName && <div className="text-danger fs-12">{errors.managerName}</div>}
                                    </div>
                                    <div className="form-group col-md-6">
                                       <label>Manager Phone Number</label>
                                       <input
                                          type="text"
                                          value={managerNumber}
                                          onChange={(e) =>
                                             setManagerNumber(e.target.value)
                                          }
                                          placeholder="Manager Phone Number"
                                          className="form-control"
                                       />
                                       {errors.managerNumber && <div className="text-danger fs-12">{errors.managerNumber}</div>}
                                    </div>
                                 </div>

                                 <div className="form-group">
                                    <label>Address</label>
                                    <input
                                       type="text"
                                       value={addLine1}
                                       onChange={(e) =>
                                          setAddLine1(e.target.value)
                                       }
                                       placeholder="e.g. 1234 Main St"
                                       className="form-control"
                                    />
                                    {errors.addLine1 && <div className="text-danger fs-12">{errors.addLine1}</div>}
                                 </div>
                                 <div className="form-group">
                                    <label>Address 2</label>
                                    <input
                                       type="text"
                                       value={addLine2}
                                       onChange={(e) =>
                                          setAddLine2(e.target.value)
                                       }
                                       placeholder="e.g. Apartment, studio, or floor"
                                       className="form-control"
                                    />
                                    {errors.addLine2 && <div className="text-danger fs-12">{errors.addLine2}</div>}
                                 </div>
                                 <div className="form-row">
                                    <div className="form-group col-md-6">
                                       <label>City</label>
                                       <input
                                          value={addCity}
                                          onChange={(e) =>
                                             setAddCity(e.target.value)
                                          }
                                          type="text"
                                          className="form-control"
                                       />
                                       {errors.addCity && <div className="text-danger fs-12">{errors.addCity}</div>}
                                    </div>
                                    <div className="form-group col-md-4">
                                       <label>State</label>
                                       <select
                                          className="form-control"
                                          id="inputState"
                                          value={addState}
                                          onChange={(e) =>
                                             setAddState(e.target.value)
                                          }
                                          defaultValue="Choose"
                                       >
                                          <option value="Choose">
                                             Choose...
                                          </option>

                                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                                          <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                          <option value="Assam">Assam</option>
                                          <option value="Bihar">Bihar</option>
                                          <option value="Chandigarh">Chandigarh</option>
                                          <option value="Chhattisgarh">Chhattisgarh</option>
                                          <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                          <option value="Daman and Diu">Daman and Diu</option>
                                          <option value="Delhi">Delhi</option>
                                          <option value="Lakshadweep">Lakshadweep</option>
                                          <option value="Puducherry">Puducherry</option>
                                          <option value="Goa">Goa</option>
                                          <option value="Gujarat">Gujarat</option>
                                          <option value="Haryana">Haryana</option>
                                          <option value="Himachal Pradesh">Himachal Pradesh</option>
                                          <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                          <option value="Jharkhand">Jharkhand</option>
                                          <option value="Karnataka">Karnataka</option>
                                          <option value="Kerala">Kerala</option>
                                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                                          <option value="Maharashtra">Maharashtra</option>
                                          <option value="Manipur">Manipur</option>
                                          <option value="Meghalaya">Meghalaya</option>
                                          <option value="Mizoram">Mizoram</option>
                                          <option value="Nagaland">Nagaland</option>
                                          <option value="Odisha">Odisha</option>
                                          <option value="Punjab">Punjab</option>
                                          <option value="Rajasthan">Rajasthan</option>
                                          <option value="Sikkim">Sikkim</option>
                                          <option value="Tamil Nadu">Tamil Nadu</option>
                                          <option value="Telangana">Telangana</option>
                                          <option value="Tripura">Tripura</option>
                                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                                          <option value="Uttarakhand">Uttarakhand</option>
                                          <option value="West Bengal">West Bengal</option>
                                       </select>

                                       {errors.addState && <div className="text-danger fs-12">{errors.addState}</div>}
                                    </div>
                                    <div className="form-group col-md-2">
                                       <label>Pincode</label>
                                       <input
                                          type="text"
                                          value={addZipcode}
                                          onChange={(e) =>
                                             setAddZipcode(e.target.value)
                                          }
                                          className="form-control"
                                       />
                                       {errors.addZipcode && <div className="text-danger fs-12">{errors.addZipcode}</div>}
                                    </div>
                                 </div>

                                 <button
                                    className="btn btn-primary"
                                    type="submit"
                                 >
                                    Save
                                 </button>
                              </form>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default MyProfile;
